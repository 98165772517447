import ReactDOM from 'react-dom/client';
import './index.css';
import './App.scss';
import { BrowserRouter, HashRouter, Navigate, Route, Router, Routes } from 'react-router-dom';
import { NotFound } from './NotFound';
import { AuthContext, AuthContextProvider } from './models/AuthContextProvider';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { Logout } from './pages/Logout';
import { TicketPage } from './pages/Ticket';
import { Settings } from './pages/Settings';
import { useContext } from 'react';
import { Tickets } from './pages/Tickets';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Lost } from './pages/Lost';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const ProtectedRoute = ({ children }: { children: any }) => {
  let context = useContext(AuthContext);
  if (!context?.me)
    return <Navigate to={`/account/login?url=${window.encodeURIComponent(window.location.pathname + (window.location.search ?? '') + (window.localStorage.hash ?? ''))}`} replace />;
  return children;
};


root.render(
  // <React.StrictMode>
  <AuthContextProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoute><Tickets /></ProtectedRoute>} />
        {/* <Route path="/ticket/:id" element={<ProtectedRoute><TicketPage /></ProtectedRoute>} /> */}
        <Route path="/account/login" element={<Login />} />
        <Route path="/account/logout" element={<Logout />} />
        <Route path="/account/register" element={<Register />} />
        <Route path="/account/lost" element={<Lost />} />
        {/* <Route path="/new" element={<ProtectedRoute><TicketPage /></ProtectedRoute>} /> */}
        <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
        {/* <Route path="/admin/op/:opid" element={<AdminOp />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </AuthContextProvider>
  // </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
