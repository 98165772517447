import { useContext, useState, useEffect, CSSProperties, useDeferredValue } from "react";
import { AuthContext } from "../models/AuthContextProvider";
import { GetRemaingDays, Order, Ticket, clone } from "../models/Product";
import AdminTemplate from "./AdminTemplate";
import React from "react";
import Moment from "react-moment";
import 'moment/locale/ru';
import { SearchParamsStateType, useSearchParamsState } from "react-use-search-params-state";
import { Button, Col, Dropdown, DropdownButton, FloatingLabel, Form, InputGroup, ListGroup, Modal, Row, ToggleButton } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useNavigate, useParams } from "react-router-dom";
import { TicketPage, saveTicket } from "./Ticket";


const filtersDefaults: SearchParamsStateType = {
    filterDone: { type: 'string', default: 'none' },
    hideOthers: { type: 'boolean', default: true },
    hideNotChecked: { type: 'boolean', default: true },
}

const execFilter = [
    { Val: 'none', Title: 'Скрыть выполненные' },
    { Val: 'week', Title: 'Выполненные за неделю' },
    { Val: 'all', Title: 'Выполненные за все время' },
]
export function Tickets() {
    let context = useContext(AuthContext);
    let navigate = useNavigate()
    const [filterParams, setFilterParams] = useSearchParamsState(filtersDefaults)
    const [data, setData] = useState<Ticket[]>([]);
    const [viewer, setViewer] = useState<Ticket | undefined>(undefined);
    const [editor, setEditor] = useState<Ticket | undefined>(undefined);
    //     const [filterText, setFilterText] = React.useState('');

    const reload = async () => {
        context.setLoading(true);
        try {
            var r = await fetch(`/Tickets/Tickets?workgroupId=${context.workgroup?.Id}`, {
                method: 'POST',
                body: JSON.stringify(filterParams),
                headers: { 'Content-Type': 'application/json' }
            });
            var { data, error } = await r.json() as { data?: Ticket[], error?: string }
            if (error) {
                context.showMessage(error, true)
            }
            if (data) {
                data.map(d => d.CreatedUser = context.workgroup?.Users?.filter(u => u.Id == d.CreatedUserId)[0])
                data.map(d => d.ExecuterUser = context.workgroup?.Users?.filter(u => u.Id == d.ExecuterUserId)[0])
                console.log('context.workgroup?.Users', context.workgroup?.Users, data)
                setData(data)
            }
        } catch (e) {
            context.showMessage('Ошибка ' + e, true)
        } finally {
            context.setLoading(false)
        }
    }
    useEffect(() => { reload() }, [filterParams.filterDone, filterParams.hideOthers, filterParams.hideNotChecked, context.workgroup]);
    useEffect(() => {
        (async () => {
            if (viewer && viewer.ExecuterUserId == context.me?.Id && viewer.StartDate == undefined)
                saveTicket(context, { ...viewer, StartDate: new Date() }, reload)
        })()
    }, [viewer])

    const getRowProps = (t: Ticket) => {
        var color = 'light'
        var style = {} as CSSProperties
        var lbl = [] as JSX.Element[]
        if (t.HighPriority) lbl.push(<div>Приоритетная</div>)
        if (t.ExecuterUserId != context.me?.Id) color = 'secondary'
        if (!t.Canceled) {
            if (!t.FinishDate) {
                if (GetRemaingDays(t) < 0 && t.ExecuterUserId == context.me?.Id) color = 'danger'
                if (GetRemaingDays(t) < 0 && t.ExecuterUserId != context.me?.Id) color = 'warning'
                if (!t.StartDate) {
                    lbl = [<div>Не просмотрена</div>]
                    style = { fontWeight: "bold" }
                } else if (t.ExecuterUserId != context.me?.Id) {
                    lbl = [<div>Выполняется</div>]
                }
            }
            if (t.FinishDate) {
                // задача  закончена
                if (t.ExecuterUserId == context.me?.Id) {
                    // выполняю задачу я
                    lbl = [<div>Готово</div>]
                    style = { textDecoration: "line-through" }
                }
                if (t.CreatedUserId == context.me?.Id) {
                    // проверяю задачу я
                    color = 'success' // выполнена, надо проверить
                    if (t.ControlDate)
                        style = { textDecoration: "line-through" }
                    else
                        lbl = [<div>Проверить</div>]
                }
            }
        } else {
            // отменена
            style = { textDecoration: "line-through" } // выполнена кем то и проверена мной
            lbl = [<div>Отменена</div>]
        }
        if (t.ExecuterComment.length > 0) lbl.push(<div className="text-nowrap">Ответ от {t.ExecuterUser?.UserName}</div>)

        const actions = [] as JSX.Element[]

        if (t.CreatedUserId == context.me?.Id)
            actions.push(<>
                <Button size="sm" variant="outline-primary" onClick={() => setEditor(clone(t))}>
                    <Icon.Pencil />
                </Button>
                {/* отмена заявки */}
                <Button size="sm" variant="outline-danger"
                    onClick={() => saveTicket(context, { ...t, Canceled: !t.Canceled }, reload)}>
                    {t.Canceled ? <Icon.ArrowCounterclockwise title="Восстановить" /> : <Icon.Trash3 title="Отменить" />}
                </Button>
            </>)

        if (!t.Canceled && t.ExecuterUserId == context.me?.Id)
            actions.push(<Button size="sm" variant="outline-primary" onClick={() => {
                var noControl = t.NoControl || t.CreatedUserId == context.me?.Id
                var finish = !t.FinishDate
                saveTicket(context, {
                    ...t,
                    FinishDate: finish ? new Date() : undefined,
                    ControlDate: noControl && finish ? new Date() : undefined
                }, reload)
            }}>
                {!t.FinishDate ? <><Icon.CheckLg /> Выполнена</> : <><Icon.XLg /> Не выполнена</>}
            </Button>)

        if (t.CreatedUserId == context.me?.Id && t.FinishDate)
            actions.push(<Button size="sm" variant="outline-primary"
                onClick={() => saveTicket(context, {
                    ...t,
                    ControlDate: t.ControlDate ? undefined : new Date()
                }, reload)}>
                {!t.ControlDate ? <><Icon.Check2All /> Проверена</> : <><Icon.XLg /> Не проверена</>}
            </Button>)

        return { actions: actions, lbl: lbl, color: color, style: style }
    }

    return (<AdminTemplate>
        {context.workgroup && <div className="p-2">
            <h5 className="text-light">Заявки</h5>
            <Row className="mb-4">
                <Col className="d-flex my-1" style={{ gap: '0.5rem' }} md='3'  >
                    <Button variant="outline-light" onClick={() => context.workgroup && setEditor({
                        Id: crypto.randomUUID(),
                        Canceled: false,
                        DaysToExecute: 1,
                        HighPriority: false,
                        ExecuterComment: '',
                        Comment: '',
                        CreatedUserId: context.me?.Id ?? '',
                        CreatedUser: context.me,
                        ExecuterUserId: context.me?.Id ?? '',
                        ExecuterUser: context.me,
                        NoControl: false,
                        TicketType: '',
                        CreationDate: new Date(),
                        WorkgroupId: context.workgroup.Id
                    })}>
                        <Icon.PlusLg className="me-2" />
                        Новая заявка
                    </Button>
                    <Button variant="outline-light" onClick={reload}>
                        Обновить
                    </Button>
                </Col>
                <Col className="d-flex justify-content-md-end my-1 flex-column flex-md-row " style={{ gap: '0.5rem' }} md='9'>

                    <DropdownButton id="dropdown-filterDone" title={execFilter.filter(e => e.Val == filterParams.filterDone)[0]?.Title} variant="light">
                        {execFilter.map(e => <Dropdown.Item onClick={() => setFilterParams({ filterDone: e.Val })}>{e.Title}</Dropdown.Item>)}
                    </DropdownButton>

                    <Button
                        id="hideOthersCb"
                        variant={filterParams.hideOthers ? "light" : 'outline-light'}
                        onClick={(e) => setFilterParams({ hideOthers: !filterParams.hideOthers })}
                    >
                        Скрыть чужие
                    </Button>

                    <Button
                        id="hideNotCheckedCb"
                        variant={filterParams.hideNotChecked ? "light" : 'outline-light'}
                        onClick={(e) => setFilterParams({ hideNotChecked: !filterParams.hideNotChecked })}
                    >
                        Скрыть непроверенные
                    </Button>
                </Col>
            </Row>

            <div className="d-flex d-lg-none flex-column mt-3" style={{ gap: '1rem' }}>
                {data.map(row => {
                    const { actions, lbl, color, style } = getRowProps(row)

                    return <div style={{ ...style }} className="bg-gray p-2 rounded-2">
                        <div onClick={(e) => row.CreatedUserId == context.me?.Id ? setEditor(clone(row)) : setViewer(row)} className={`position-relative p-1 d-flex flex-column mb-2`} style={{ gap: '0.11rem' }}>
                            <div className={`bg-${color}`} style={{
                                position: 'absolute', right: '.5rem', top: '.5rem', width: '2rem', height: '2rem', borderRadius: '50%'
                            }}></div>
                            {lbl}

                            <div><b>Что:</b> {row.TicketType}</div>
                            <div><b>Дней:</b> {GetRemaingDays(row)}</div>
                            <div><b>Создана:</b> <Moment locale="ru" format="DD.MM.YYYY">{row.CreationDate}</Moment></div>
                            <div><b>кто кому:</b> {row.CreatedUser?.UserName ?? '?'} <Icon.ArrowRight /> {row.ExecuterUser?.UserName ?? '?'}</div>

                            <div title={row.Comment} style={{
                                maxWidth: 'calc(100vw - 1rem)',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: "hidden"
                            }}>
                                {row.Comment}
                            </div>
                        </div>

                        <div>
                            <div className="d-flex flex-wrap" style={{ gap: '0.5rem' }}>{actions}</div>
                        </div>
                    </div>
                })}
            </div>

            {/* <table className="table table-hover w-100 tickets d-none d-md-table"> */}
            <div className="d-none d-lg-flex w-100 flex-column" style={{ gap: '1rem' }}>
                {data.map(row => {
                    const { actions, lbl, color, style } = getRowProps(row)

                    return <div style={{ ...style, "gap": ".9rem" }}
                        className={`align-items-center ticket shadow m-0 p-3 d-flex flex-row alert alert-${color} rounded-2`}
                        onClick={(e) => row.CreatedUserId == context.me?.Id ? setEditor(clone(row)) : setViewer(row)}>
                        <div className="text-truncate" style={{ width: '14rem', minWidth: '14rem' }}>
                            <div className="fs-5">{row.TicketType}</div>
                            {lbl}
                        </div>
                        <div style={{ width: '8rem', minWidth: '8rem' }}>
                            <Moment locale="ru" format="DD.MM.YYYY" className="fs-4">{row.CreationDate}</Moment>
                        </div>
                        <div className="text-truncate" style={{ width: '8rem', minWidth: '8rem' }}>
                            {row.CreatedUser?.UserName} <Icon.ArrowRight /> {row.ExecuterUser?.UserName}
                        </div>
                        <div className="fs-4 text-center" style={{ width: '4rem', minWidth: '4rem' }} title="Осталось дней">
                            {GetRemaingDays(row)}<span className="fs-6"> д.</span>
                        </div>
                        <div title={row.Comment} className="text-truncate flex-fill d-none d-lg-block">
                            {row.Comment}
                        </div>
                        <div style={{
                            width: '25rem', maxWidth: '25rem', minWidth: '25rem',
                            gap: '0.5rem', justifyContent: 'end'
                        }}
                            className="d-flex flex-wrap" onClick={(e) => e.stopPropagation()}>
                            {actions}
                        </div>
                    </div>
                })}
            </div>
        </div>
        }


        {viewer && <>
            <Modal show={viewer != undefined} onHide={() => setViewer(undefined)} centered size="lg" backdrop="static">
                <Modal.Header>
                    <Modal.Title>Просмотр заявки</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="fs-4 mb-3"><u>{viewer.TicketType}</u></div>
                    {viewer.Comment.length > 0 && <>
                        <div>Комментарий:</div>
                        <pre className="fs-6 mt-3" style={{ whiteSpace: "pre-wrap" }}>{viewer.Comment}</pre>
                    </>}

                    <FloatingLabel label="Комментарий исполнителя" className="has-validation w-100">
                        <Form.Control as="textarea" rows={7} placeholder="Комментарий исполнителя" value={viewer.ExecuterComment}
                            onChange={(e) => setViewer({ ...viewer, ExecuterComment: e.target.value })}
                            style={{ height: '30vh' }} />
                    </FloatingLabel>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        setViewer(undefined)
                    }}>Закрыть без сохранения</Button>
                    <Button variant="primary" className="ms-2" onClick={() => saveTicket(context, viewer, () => {
                        setViewer(undefined)
                        reload()
                    })}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </>}


        {editor && <>
            <Modal show={editor != undefined} onHide={() => setEditor(undefined)} centered size="lg" backdrop="static">
                <Modal.Header>
                    <Modal.Title>Заявка</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TicketPage ticket={editor} setTicket={setEditor} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        if (window.confirm('Точно закрыть без сохранения?')) setEditor(undefined)
                    }}>Закрыть без сохранения</Button>
                    <Button variant="primary" className="ms-2" disabled={context.isLoading} onClick={() => saveTicket(context, editor, () => {
                        setEditor(undefined)
                        reload()
                    })}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </>}

    </AdminTemplate>)
}


