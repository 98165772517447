import { NavLink } from 'react-router-dom';
import { Alert, AlertHeading, AlertLink, Col, Container, Nav, NavDropdown, Navbar, Row } from 'react-bootstrap';
import React, { useContext } from 'react';
import { TableStyles } from 'react-data-table-component';
import { AuthContext } from '../models/AuthContextProvider';

export const tableStyles: TableStyles = {
    rows: {
        style: {
            minHeight: '40px',
        }
    },
    headCells: {
        style: {
            fontWeight: '600', // override the cell padding for head cells
            fontSize: '14px'
        },
    },
    cells: {
        style: {
            fontSize: '14px'
        },
    },
};

export default function AdminTemplate({ children }: { children: React.ReactNode }) {
    let context = useContext(AuthContext);
    return <div className='d-flex flex-column' style={{ minHeight: '100vh' }}>

        <Navbar expand="md" className="bg-body-tertiary px-2 shadow">
            <Container fluid>
                <Navbar.Brand href="/">Выполник</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {context.me && <>
                            <NavLink to="/" className={({ isActive }) => `nav-link ${(isActive ? 'active bg-white text-danger py-1 mt-1' : '')}`}>
                                Мои заявки</NavLink>
                            <NavLink to="/settings" className={({ isActive }) => `nav-link ${(isActive ? 'active bg-white text-danger py-1 mt-1' : '')}`}>
                                Аккаунт</NavLink>
                            <NavLink to="/account/logout" className={({ isActive }) => `nav-link ${(isActive ? 'active bg-white text-danger py-1 mt-1' : '')}`}>
                                Выход</NavLink>

                            <NavDropdown title={context.workgroup?.Title ?? "Не выбрана"} id="basic-nav-dropdown">
                                {context.me?.Workgroups.map(wg => <NavDropdown.Item onClick={() => context.setWorkgroup(wg.Id)}>{wg.Title}</NavDropdown.Item>)}
                            </NavDropdown>
                        </>}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        {context.me && <>
            {(context.me.PhoneNumber?.length ?? 0) == 0 && <Alert className='m-3' variant='danger'>
                Не указан телефонный номер для уведомлений и восстановления пароля.
                Укажите телефонный номер в <NavLink to="/settings" className='alert-link'>настройках аккаунта</NavLink>
            </Alert>}

            {(context.me.PhoneNumber?.length ?? 0) > 0 && !context.me?.PhoneNumberConfirmed && <Alert className='m-3' variant='danger'>
                Номер телефона не подтверждён.
                Подтвердите телефон с помощью кода из SMS в <NavLink to="/settings" className='alert-link'>настройках аккаунта</NavLink>
            </Alert>}
        </>}
        <div style={{ flexGrow: '2', display: "grid", gridTemplateColumns: "100%", gridTemplateRows: "auto" }} className='m-2'>
            {children}
        </div>
    </div>
}