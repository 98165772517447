import { useContext, useState } from "react";
import { AuthContext } from "../models/AuthContextProvider";
import React from "react";
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';


export function SettingsWorkgroups() {
    let navigate = useNavigate();
    let context = useContext(AuthContext);
    const [invite, setInvite] = useState<number | undefined>();
    const [newWgTitle, setNewWgTitle] = useState<string>();
    const wgApi = async (url: string) => {
        context.setLoading(true);
        try {
            const response = await fetch(url, {
                method: 'POST',
            });
            console.log(`${url} result`, response);
            var { message, error } = await response.json() as {
                message?: string;
                error?: string;
            };
            if (message) {
                context.showMessage(message, false);
                if (await context.reload())
                    navigate('/settings');
                else
                    navigate('/account/login');
            }
            else {
                context.showMessage(error ?? 'Ошибка', true);
            }
        } catch (e) {
            context.showMessage('Ошибка ' + e, true);
        } finally {
            context.setLoading(false);
        }
    };

    return <>
        <h5 className="mb-4">Рабочие группы</h5>
        {context.me?.Workgroups.map(wc => <>
            <h6 className="mt-2">
                <span style={{ cursor: "pointer" }} onClick={async () => {
                    var newName = window.prompt('Введите имя группы', wc.Title);
                    if (!newName) return;
                    await wgApi(`/Tickets/RenameWorkgroup/${wc.Id}?title=${newName}`);
                }}>
                    {wc.Title}
                    <Icon.Pencil className="mx-2" size={12} />
                </span>
                <span className="badge bg-success">Код приглашения: {wc.InviteCode}</span>
            </h6>
            <ul>
                {wc.Users.map(u => <li><b>{u.UserName}</b> - {u.Fio}</li>)}
            </ul>
        </>)}

        <form className="mt-4" onSubmit={async (e) => {
            e.preventDefault();
            await wgApi(`/Tickets/JoinWorkgroup/${invite}`);
        }}>
            <h6>Вступить</h6>
            <Row>
                <Col md={8}>
                    <FloatingLabel label="Код приглашения" className="has-validation">
                        <Form.Control type="text" placeholder="Код приглашения" required
                            value={invite} onChange={e => {
                                var v = parseInt(e.target.value);
                                setInvite(!isNaN(v) ? v : undefined);
                            }} />
                    </FloatingLabel>
                </Col>
                <Button type="submit" variant="primary" className="col md-4" disabled={context.isLoading}>Вступить в рабочую группу</Button>
            </Row>
        </form>

        <form className="mt-4" onSubmit={async (e) => {
            e.preventDefault();
            await wgApi(`/Tickets/CreateWorkgroup?title=${newWgTitle}`);
        }}>
            <h6>Создать рабочую группу</h6>
            <Row>
                <Col md={8}>
                    <FloatingLabel label="Название группы" className="has-validation">
                        <Form.Control type="text" placeholder="Название группы" required
                            value={newWgTitle} onChange={e => setNewWgTitle(e.target.value)} />
                    </FloatingLabel>
                </Col>
                <Button type="submit" variant="primary" className="col md-4" disabled={context.isLoading}>Создать рабочую группу</Button>
            </Row>

        </form>
    </>;
}
