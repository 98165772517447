import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AdminTemplate from './pages/AdminTemplate';
import { AuthContext } from './models/AuthContextProvider';

export function NotFound() {
  let context = useContext(AuthContext);
  useEffect(() => context.setLoading(false),[])
  return <AdminTemplate>
    <div className="p-4 bg-white rounded-3">
      <h1 className='m-3 text-center'>Страница не найдена</h1></div>
  </AdminTemplate>;
}
