import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../models/AuthContextProvider";
import { Order, Ticket, User, clone } from "../models/Product";
import AdminTemplate from "./AdminTemplate";
import React from "react";
import Moment from "react-moment";
import 'moment/locale/ru';
import { Button, Col, FloatingLabel, Form, InputGroup, Modal, ProgressBar, Row, Tab, Tabs } from 'react-bootstrap';
import { Hint, Typeahead } from "react-bootstrap-typeahead";
import { TypeaheadInputProps } from "react-bootstrap-typeahead/types/types";
import { useNavigate } from "react-router-dom";
import { SettingsWorkgroups } from "./SettingsWorkgroups";
import { saveTicket } from "./Ticket";

export function Settings() {
    let navigate = useNavigate();
    let context = useContext(AuthContext);
    const [me, setMe] = useState<User>(context.me ?? {
        Enabled: true, Fio: '', IsAdmin: false, UserName: '', Id: '', TicketTypes: [], Workgroups: [],
        PhoneNumberConfirmed: false, PhoneNumber: undefined
    } as User);
    const [smsMessage, setSmsMessage] = useState<string | undefined>(undefined);
    const [smsCode, setSmsCode] = useState<string>('');



    const sendSmsCode = async () => {
        context.setLoading(true)
        setSmsMessage(undefined)
        try {
            const response = await fetch('/Tickets/SendSmsCode', {
                method: 'POST',
            })
            var { message, error } = await response.json() as {
                message?: string
                error?: string
            }
            if (message) {
                setSmsMessage(message)
            }
            else {
                context.showMessage(error ?? 'Ошибка', true)
            }
        } catch (e) {
            context.showMessage('Ошибка ' + e, true)
        } finally {
            context.setLoading(false)
        }
    }


    const confirmSmsCode = async () => {
        context.setLoading(true)
        try {
            if (!(smsCode?.length > 0)) {
                context.showMessage("Введите код", false)
                return
            }
            const response = await fetch('/Tickets/ConfirmSmsCode?' + new URLSearchParams({ code: smsCode }),
                {
                    method: 'POST',
                })
            var { message, error } = await response.json() as {
                message?: string
                error?: string
            }
            if (message) {
                setSmsMessage(undefined)
                context.showMessage(message, false)
                var newContext = await context.reload()
                if (newContext?.me)
                    setMe(newContext.me)
                else
                    navigate('/account/login')
            }
            else {
                setSmsMessage(error ?? 'Ошибка')
            }
        } catch (e) {
            setSmsMessage('Ошибка ' + e)
        } finally {
            context.setLoading(false)
        }
    }

    return (<AdminTemplate>
        <Row className="m-4">
            <Col md={6}>
                <div className="p-4 bg-white rounded-3 mb-3">
                    <h5 className="mb-4">Настройки</h5>
                    {/* <code>{JSON.stringify(me)}</code> */}

                    <form onSubmit={async (e) => {
                        e.preventDefault();
                        context.setLoading(true)
                        try {
                            const response = await fetch('/Tickets/SaveUser', {
                                method: 'POST',
                                body: JSON.stringify(me),
                                headers: { 'Content-Type': 'application/json' }
                            })
                            console.log('Register result', response)
                            var { message, error } = await response.json() as {
                                message?: string
                                error?: string
                            }
                            if (message) {
                                context.showMessage(message, false)
                                var newContext = await context.reload()
                                if (newContext?.me)
                                    setMe(newContext.me)
                                else
                                    navigate('/account/login')
                            }
                            else {
                                context.showMessage(error ?? 'Ошибка', true)
                            }
                        } catch (e) {
                            context.showMessage('Ошибка ' + e, true)
                        } finally {
                            context.setLoading(false)
                        }
                    }}>

                        <FloatingLabel label="ФИО" className="mb-3 has-validation">
                            <Form.Control type="text" placeholder="ФИО" required
                                value={me.Fio} onChange={e => setMe({ ...me, Fio: e.target.value })}
                            />
                        </FloatingLabel>

                        <div className="mb-3 d-flex" style={{ gap: '1rem' }}>
                            <FloatingLabel label="Телефон для уведомлений" className="has-validation w-100">
                                <Form.Control type="text" placeholder="Телефон для уведомлений" required
                                    value={me.PhoneNumber} onChange={e => setMe({ ...me, PhoneNumber: e.target.value })}
                                />
                            </FloatingLabel>
                            {((me.PhoneNumber?.length ?? 0) > 0 && me.PhoneNumber == context.me?.PhoneNumber && !me.PhoneNumberConfirmed) && <>
                                <Button onClick={e => sendSmsCode()} variant="danger">Подтвердить по SMS</Button>
                            </>}
                        </div>
                        <div className="fs-5 mb-2">Типы тикетов</div>
                        <div>
                            {me.TicketTypes?.map((t, i) => <InputGroup className="mb-2">
                                <Form.Control type="text" placeholder="Тип тикета" required
                                    value={t.TicketTypeTitle} onChange={e => setMe((u) => {
                                        var me2 = clone(u);
                                        me2.TicketTypes[i].TicketTypeTitle = e.target.value
                                        return me2;
                                    })}
                                />
                                <Button onClick={e => setMe((u) => {
                                    var me2 = clone(u);
                                    me2.TicketTypes.splice(i, 1)
                                    return me2;
                                })}>x</Button>
                            </InputGroup>)}
                            <div>
                                <Button onClick={e => setMe({ ...me, TicketTypes: [...me.TicketTypes, { TicketTypeTitle: '', Id: crypto.randomUUID() }] })}>Добавить</Button>
                            </div>
                        </div>

                        <Button type="submit" variant="primary" className="mt-5 w-100" disabled={context.isLoading}>Сохранить</Button>
                    </form>
                </div>
            </Col>
            <Col md={6}>
                <div className="p-4 bg-white rounded-3">
                    <SettingsWorkgroups />
                </div>
            </Col>
        </Row>

        {smsMessage && <>
            <Modal show={smsMessage != undefined} onHide={() => setSmsMessage(undefined)} centered size="lg" backdrop="static">
                <Modal.Header>
                    <Modal.Title>Введите SMS-код</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2">{smsMessage}</div>
                    <FloatingLabel label="SMS код" className="has-validation w-100">
                        <Form.Control placeholder="SMS код" value={smsCode} required
                            onChange={(e) => setSmsCode(e.target.value)} />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setSmsMessage(undefined)}>Отмена</Button>
                    <Button variant="primary" className="ms-2" onClick={() => confirmSmsCode()}>Отправить</Button>
                </Modal.Footer>
            </Modal>
        </>}
    </AdminTemplate>)
}