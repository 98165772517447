import { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { env } from 'process';
import { Modal } from "react-bootstrap";
import Spinner from "../components/Spinner";
import { Card, User, Workgroup } from "./Product";
import { useLocation, useNavigate } from "react-router-dom";

export const IsMobile = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return width < 600;
}
export const AuthContext = createContext<AuthData>({
  isLoading: false,
  setLoading: (n) => { },
  showMessage: (t, e) => { },
  reload: async () => { return undefined },
  me: undefined,
  setWorkgroup: (s) => { },
  workgroup: undefined
});

type SizeVariantsType = ("sm" | "lg" | "xl" | undefined)
export interface AuthData {
  isLoading: boolean
  setLoading: (newState: boolean) => void
  showMessage: (text: string | JSX.Element, isError: boolean, size?: SizeVariantsType) => void
  reload: () => Promise<AuthData | undefined>
  me: User | undefined
  setWorkgroup: (id: string) => void
  workgroup: Workgroup | undefined
}

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  // let navigate = useNavigate();
  //let location = useLocation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const [workgroup, setWorkgroup] = useState<Workgroup | undefined>();
  const [me, setMe] = useState<User | undefined>();
  //const isAccountPages = location.pathname.startsWith('/account') == true

  const [messState, setMessState] = useState({
    menuClosed: true, message: '' as (string | JSX.Element),
    isMessageErr: false,
    size: undefined as SizeVariantsType
  });
  const showMessage = (text: string | JSX.Element, isError: boolean, size?: SizeVariantsType) => {
    setMessState(s => ({ ...s, message: text, isMessageErr: isError, size: size }));
  }
  const setWorkgroup2 = (id: string) => {
    var wc = me?.Workgroups?.filter(w => w.Id == id)[0];
    if (!wc) {
      showMessage(`Рабочая группа с id ${id} не найдена у пользователя ${me?.Fio}`, true);
      return;
    }
    setWorkgroup(wc);
    window.localStorage.setItem('wgid', id)
  }

  const reload = async () => {
    console.log('reload all')
    setLoading(true);
    try {
      var { me, wg, message } = await (await fetch("/Users/Me")).json() as { me: User, message?: string, wg: Workgroup[] }
      console.log('fetched me', me);
      // var js = await (await fetch("/CatalogApi/Stores")).json() as Store[];
      // console.log('fetched stores', js);
      // var jo = await (await fetch("/CatalogApi/GetOpTypes")).json() as OperationType[];
      // console.log('fetched GetOpTypes', jo);
      // var jops = await (await fetch("/CatalogApi/ProductionOps")).json() as ProductionOp[];
      // console.log('fetched Ops', jops);
      me.Workgroups = wg;
      if (wg.length > 0) {
        var defId = window.localStorage.getItem('wgid') ?? me.Workgroups[0].Id
        setWorkgroup(me.Workgroups.filter(f => f.Id == defId)[0])
      }
      setMe(me)
      // setUsers(ju.sort((a, b) => a.Fio > b.Fio ? 1 : -1));
      // setStores(js);
      // setOpertionTypes(jo);
      // setOps(jops)
      setReady(true)
      return {
        me, workgroup
      } as AuthData
    } catch (e) {
      setMe(undefined)
      return undefined
    } finally {
      setLoading(false);
    }
  }

  //if (me == null && !isAccountPages) return <>Не авторизован</>
  return (
    <AuthContext.Provider value={{ isLoading, setLoading, showMessage, reload, me, setWorkgroup: setWorkgroup2, workgroup }}>
      <Spinner />
      {children}

      <Modal show={messState.message !== ''} onHide={() => setMessState({ ...messState, message: '' })} centered style={{ zIndex: 11111 }} size={messState.size} id="MessageModal">
        <Modal.Header closeButton>
          <Modal.Title>{messState.isMessageErr ? 'Ошибка' : 'Сообщение'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{messState.message}</p>
        </Modal.Body>
      </Modal>

    </AuthContext.Provider>
  )
  return <></>
};
