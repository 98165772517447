import { useContext, useState, useEffect } from "react";
import { AuthContext, AuthData } from "../models/AuthContextProvider";
import { Ticket, User } from "../models/Product";
import 'moment/locale/ru';
import { Button, FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import { Hint, Typeahead } from "react-bootstrap-typeahead";
import { TypeaheadInputProps } from "react-bootstrap-typeahead/types/types";
import { useNavigate, useParams } from "react-router-dom";

export const ri = (label: string) =>
    ({ inputRef, referenceElementRef, ...inputProps }: TypeaheadInputProps) => {
        return (
            <Hint>
                <FloatingLabel controlId="floatingLabel" label={label}>
                    <Form.Control
                        {...inputProps as any}
                        ref={(node: any) => {
                            inputRef(node);
                            referenceElementRef(node);
                        }}
                    />
                </FloatingLabel>
            </Hint>
        );
    }

export async function saveTicket(context: AuthData, data: Ticket, success: () => void) {
    context.setLoading(true)
    try {
        const response = await fetch('/Tickets/SaveTicket', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' }
        })
        console.log('Register result', response)
        var { message, error } = await response.json() as {
            message?: string
            error?: string
        }
        if (message) {
            if (message != 'OK') context.showMessage(message, false)
            success();
        }
        else {
            context.showMessage(error ?? 'Ошибка', true)
        }
    } catch (e) {
        context.showMessage('Ошибка ' + e, true)
    } finally {
        context.setLoading(false)
    }
}

interface IProps {
    ticket: Ticket
    setTicket: (t: Ticket) => void
}

export function TicketPage(props: IProps) {
    let navigate = useNavigate();
    let context = useContext(AuthContext);
    const { ticket: data, setTicket: setData } = props;

    // const reload = async () => {
    //     context.setLoading(true);
    //     try {
    //         var r = await fetch(`/Tickets/Tickets/${ticketId}`);
    //         var j = await r.json()
    //         console.log('fetched Tickets/Tickets', j);
    //         var { data, error } = j as { data?: Ticket[], error?: string }
    //         if (error) {
    //             context.showMessage(error, true)
    //         }
    //         if (data?.length == 1) {
    //             data.map(d => d.CreatedUser = context.users.filter(u => u.Id == d.CreatedUserId)[0])
    //             data.map(d => d.ExecuterUser = context.users.filter(u => u.Id == d.ExecuterUserId)[0])
    //             setData(data[0])
    //         }
    //     } catch (e) {
    //         context.showMessage('Ошибка ' + e, true)
    //     } finally {
    //         context.setLoading(false)
    //     }
    // }
    // useEffect(() => { if (ticketId && ticketId.length > 0) reload() }, []);




    return (
        <div className="p-2">
            <h5>Заявка</h5>

            {/* <code>{JSON.stringify(data)}</code> */}

            <div className="d-flex flex-column" style={{ gap: '1rem' }}>
                <Typeahead placeholder="Исполнитель" className="has-validation"
                    //defaultSelected={[data.ExecuterUser] as Option[]}
                    selected={data.ExecuterUser ? [data.ExecuterUser] : []}
                    options={context.workgroup?.Users ?? []}
                    onChange={(t) => {
                        var usr = t.length > 0 ? (t[0] as User) : undefined
                        setData({ ...data, ExecuterUser: usr, ExecuterUserId: usr?.Id })
                    }}
                    id={`users-defstoreid`}
                    filterBy={() => true}
                    labelKey={'Fio'}
                    key={'Id'}
                    isInvalid={data.ExecuterUser == null}
                    isValid={data.ExecuterUser != null}
                    renderInput={ri('Исполнитель')}
                />

                <Typeahead placeholder="Что" className="has-validation w-100"
                    selected={data.TicketType ? [data.TicketType] : []}
                    options={data.ExecuterUser?.TicketTypes.map(p => p.TicketTypeTitle) ?? []}
                    onInputChange={t => setData({ ...data, TicketType: t })}
                    onChange={t => t.length > 0 && setData({ ...data, TicketType: (t[0] as any).label ?? t[0].toString() })}
                    id={`what-val`}
                    filterBy={() => true}
                    renderInput={ri("Что")}
                />

                <div>
                    <InputGroup className="mt-auto flex-nowrap" style={{ width: "9rem" }}>
                        <Button className="btn btn-dark" onClick={() => setData({ ...data, DaysToExecute: data.DaysToExecute - 1 })} disabled={data.DaysToExecute == 0}>&minus;</Button >
                        <Form.Control
                            placeholder="Дней"
                            aria-label="Дней"
                            type="number"
                            min={0}
                            step={1}
                            //max={actions.length}
                            value={data.DaysToExecute}
                            onChange={(e) => setData({ ...data, DaysToExecute: parseFloat(e.target.value) })}
                            style={{ textAlign: "center" }}
                        />
                        <Button className="btn btn-dark" onClick={() => setData({ ...data, DaysToExecute: data.DaysToExecute + 1 })}>+</Button >
                    </InputGroup>
                </div>

                <FloatingLabel label="Комментарий" className="has-validation w-100">
                    <Form.Control as="textarea" rows={7} placeholder="Комментарий" value={data.Comment}
                        onChange={(e) => setData({ ...data, Comment: e.target.value })}
                        style={{ height: '30vh' }} />
                </FloatingLabel>

                {data.ExecuterUserId != context.me?.Id && data.ExecuterComment?.length > 0 && <>
                    <div>Комментарий исполнителя:</div>
                    <pre className="fs-6">{data.ExecuterComment}</pre>
                </>}
                {data.ExecuterUserId == context.me?.Id && <FloatingLabel label="Комментарий исполнителя" className="has-validation w-100">
                    <Form.Control as="textarea" rows={7} placeholder="Комментарий исполнителя" value={data.ExecuterComment}
                        onChange={(e) => setData({ ...data, ExecuterComment: e.target.value })}
                        style={{ height: '100px' }} />
                </FloatingLabel>}


            </div>
        </div >)
}