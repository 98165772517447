import moment from "moment"
import { Tickets } from "../pages/Tickets"

export type Card = {
  nmID: number // Артикул WB
  imtID: number
  subjectName: string //Название предмета
  vendorCode: string
  brand: string
  title: string
  description: string
  photos: {
    big: string
    // small: string
  }[]
  dimensions: { // Габариты упаковки товара, см
    length: number
    width: number
    height: number
  }
  characteristics: {
    id: number
    name: string
    value: string[] | number
  }
  sizes: { // Размеры товара
    chrtID: number // Числовой идентификатор размера для данного артикула WB
    techSize: string // Размер товара (А, XXL, 57 и др.)
    wbSize: string // Российский размер товара
    skus: string[] // Баркод товара
  }
  createdAt: string
  updatedAt: string
}

export type Order = {
  id: number // Идентификатор сборочного задания в Маркетплейсе
  price: number
  convertedPrice: number
  createdAt: string
  skus: string[]
  deliveryType: string
  nmId: number // Артикул WB
  chrtId: number // Идентификатор размера товара в системе Wildberries
  article: string // Артикул продавца

  card: Card | undefined
}


export type User = {
  Id: string
  Fio: string
  Enabled: boolean
  UserName: string
  IsAdmin: boolean
  TicketTypes: TicketType[]
  Workgroups: Workgroup[]
  PhoneNumber: string | undefined
  PhoneNumberConfirmed: boolean
}

export type TicketType = {
  Id: string
  TicketTypeTitle: string
}
export function GetRemaingDays(t: Ticket) {
  if (!t.CreationDate) return 0
  return  t.DaysToExecute - moment().diff(t.CreationDate, 'days')
}
export type Ticket = {
  Id: string
  TicketType: string
  DaysToExecute: number
  CreationDate: Date | undefined
  StartDate?: Date | undefined
  FinishDate?: Date | undefined
  ControlDate?: Date | undefined
  HighPriority: boolean
  ExecuterComment: string
  Comment: string
  CreatedUserId: string | undefined
  CreatedUser?: User
  ExecuterUserId: string | undefined
  ExecuterUser?: User
  Canceled: boolean
  NoControl: boolean
  WorkgroupId: string
}

export type Workgroup = {
  Id: string
  Title: string
  Users: User[]
  InviteCode: number
}
export function clone<T>(a: T): T {
  return JSON.parse(JSON.stringify(a));
}
