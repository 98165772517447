import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Container, FloatingLabel, Form } from "react-bootstrap";
import AdminTemplate from "./AdminTemplate";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../models/AuthContextProvider";



export function Login() {
  let navigate = useNavigate();
  let context = useContext(AuthContext);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | undefined>();
  const [disabled, setDisabled] = useState<boolean>();

  useEffect(() => {
    (async () => {
      const url = new URLSearchParams(window.location.search).get('url') ?? '/'
      if (await context.reload()) navigate(url)
    })()
  }, [])

  return (
    <AdminTemplate>
      <Container style={{ maxWidth: '40rem' }}>
        <form className="mt-4 p-3 bg-white shadow rounded" onSubmit={async (e) => {
          e.preventDefault();
          const response = await fetch('/Users/Login?' + new URLSearchParams({
            username: login,
            password: password
          }), {
            method: 'POST'
          })
          var jresult = await response.json();
          var { Succeeded, error, IsLockedOut, IsNotAllowed } = jresult as {
            Succeeded: boolean,
            error?: string,
            IsLockedOut: boolean,
            IsNotAllowed: boolean,
            RequiresTwoFactor: boolean
          }
          console.log('login result', response, jresult)
          if (Succeeded && await context.reload()) {
            navigate('/')
            return
          }
          if (IsLockedOut) context.showMessage('IsLockedOut', true)
          else if (IsNotAllowed) context.showMessage('IsNotAllowed', true)
          else context.showMessage('Ошибка входа ' + (error ?? ''), true)
        }
        }>
          <div className='mb-3 fs-5'>Вход</div>
          <FloatingLabel label="Логин" className="mb-3 has-validation">
            <Form.Control type="text" placeholder="Логин" name="user_login" required
              value={login} onChange={e => setLogin(e.target.value)}
            />
            {error && <div className={`invalid-feedback loginForm_invalid_feedback`}>{error}</div>}
          </FloatingLabel>

          <FloatingLabel label="Пароль" className="mb-3">
            <Form.Control type="password" placeholder="Пароль" name="user_password" required
              value={password} onChange={e => setPassword(e.target.value)} />
          </FloatingLabel>
          <Button type="submit" variant="primary" size="lg" className="mt-3 w-100" disabled={disabled}>Войти</Button>
          <Link to="/account/register" className="btn btn-link mt-2 w-100 btn-lg">Регистрация</Link>
          <Link to="/account/lost" className="btn btn-link mt-2 w-100 btn-lg">Восстановление пароля</Link>
        </form>
      </Container>
    </AdminTemplate>
  );

}